import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getProjects } from '../services/api';
import profileImg from "../assets/images/img.png"
import { IoSettingsOutline, IoSearchOutline } from "react-icons/io5";
import noActivity from "../assets/images/no-task.png"


const Project = () => {
    let navigate = useNavigate();
    const [searchShow, setSearchShow] = useState(false)
    const Session = JSON.parse(sessionStorage.getItem('User'));
    if (!Session) return navigate(`/`);

    const [List, setList] = useState([]);
    useEffect(() => {
        ProjectListing();
    }, []);

    async function ProjectListing() {
        const listing = await getProjects();
        if (listing.code == 401) {
            navigate('/');
            return
        }
        setList(listing.result);
    }

    async function getProjectId(id, currentWeekSeconds, hoursLimit) {
        navigate(`/tracker/:${id}`, { state: { currentWeekSeconds, hoursLimit } });
    }
    async function logout() {
        window.api.logout("logout successfully from Tracker");
        sessionStorage.clear();
        navigate(`/`);
    }

    function secondsToHoursMinutes(seconds) {
        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds % 3600) / 60);
        var hoursStr = hours.toString().padStart(2, '0');
        var minutesStr = minutes.toString().padStart(2, '0');
        return hoursStr + 'h ' + minutesStr + 'm';
    }



    return (
        <>
            <div className="project-container">
                <div className="topbar-container">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='d-flex align-items-center'>
                            <div className="profile-img me-2">
                                <img src={profileImg} alt="" className='img-fluid' />
                            </div>
                            <div className="profile-info">
                                <h6>Welcome,</h6>
                                <h3>{Session.name}</h3>
                            </div>
                        </div>
                        <button className="setting-icon" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                            <IoSettingsOutline color='var(--primary)' />
                        </button>
                    </div>
                    {/* <div className="search-section mt-4">
                        <div className="search-box d-flex justify-content-between">
                            <div className="w-100 me-2">
                                <input type="text" placeholder="Search contracts" className="form-control me-2 w-100"></input>
                            </div>
                            <div className="refresh-btn d-flex justify-content-center align-items-center rounded-circle">
                                <i className="bi bi-arrow-clockwise"></i>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center recency">
                            <p>Recency</p>
                            <p>hours this week</p>
                        </div>
                    </div> */}
                </div>
                <div className="white-card">
                    <div className="card-header position-relative d-flex justify-content-end align-items-center">
                        <h4 className='mb-0'>My Projects</h4>
                        {/* <div className="position-relative w-100 search-box">
                            <div className="search-btn" onClick={() => setSearchShow(!searchShow)} style={{ backgroundColor: "var(--primary)" }}>
                                <IoSearchOutline color='var(--white)' />
                            </div>
                            <input type="text" style={{ right: searchShow ? "0%" : "-100%" }} className='form-control' placeholder='Search Project' />
                        </div> */}
                    </div>
                    <div className="white-card-container">
                        {
                            List.length === 0 ? <>
                                <div className='no-activity d-flex flex-column justify-content-center align-items-center'>
                                    <img src={noActivity} className='img-fluid' alt="noactivity" />
                                    <p className='text-center'>Not assigned any task yet !!!</p>
                                </div>
                            </> : List.map((item, index) => {
                                return <div className="project-item mb-3" key={index.toString()} onClick={() => getProjectId(item._id, item.currentWeekSeconds, item.hoursLimit)}>
                                    <div className="project-info d-flex justify-content-between">
                                        <div className="project-title">
                                            <h4>{item.name}</h4>
                                            <h6>Arun Sharma</h6>
                                        </div>
                                        <div className="project-time">
                                            {/* <div className="today-time">
                                                <h5>05h 30min</h5>
                                            </div> */}
                                            <div className="week-time">
                                                <span className="week-run-time">
                                                    {secondsToHoursMinutes(item.currentWeekSeconds)}
                                                </span>
                                                <span className="week-run-hour">
                                                    {` /${item.hoursLimit}hrs`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-timing d-flex justify-content-between">
                                        <div className="time-duration">
                                            <h6 className='mb-0'>3 months duration</h6>
                                        </div>
                                        <div className="time-remaining">
                                            <h6 className='mb-0'>1 month remaining</h6>
                                        </div>
                                    </div>
                                    <div className="project-progress">
                                        <div className="project-time-progress" style={{ width: "40%" }}></div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    {/* {List.map((item, index) => {
                        return (
                            <div key={index.toString()} onClick={() => getProjectId(item._id)} className="pointer" >
                                <div className="screen-container mx-2" >
                                    <div className="project-details d-flex justify-content-between align-items-center my-3">
                                        <div>
                                            <h6 className="mb-2">{item.name}</h6>
                                            <p>{item.userId.name}</p>
                                        </div>
                                        <div>
                                            <div className="hours">
                                                <div className="mb-2 hour-time">20 hrs</div>
                                                <p>of {item.hoursLimit} hrs</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })} */}
                </div>
                {/* <div className="project-footer">
                <div className="screen-container mx-2">
                    <div className="user-profile">
                        <button className='btn btn-primary' onClick={() => logout()}>Logout</button>
                    </div>
                </div>
            </div> */}
            </div>

            <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                <div className="offcanvas-body">
                    {/* <button className='btn'>Settings</button> */}
                    {/* <button className='btn'>Quit</button> */}
                    <button className='btn logout-btn' onClick={() => logout()}>Logout</button>
                </div>
            </div>
        </>
    )
}

export default Project;