// import './App.css';
import Login from './app/login';
import Project from './app/project';
import Tracker from './app/tracker'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
          <Routes>
            <Route exact path="/" element={<Login/>}/>
            <Route exact path="/project" element={<Project/>}/>
            <Route exact path="/tracker/:id" element={<Tracker/>}/>
          </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
