export const Urls = {
    // baseUrl : "http://localhost:3001/api/v2",
    // baseUrl: "http://144.126.252.42:3001/api/v2",
    baseUrl: "https://trackerapi.umeshsharma.dev/api/v2",
    login: "/user/login",
    projectListing: "/user/projectListing",
    trackingInfo: "/tracker/getInfo",
    trackingHours: "/tracker/getTrackerHours",
    checkUser: "/user/checkUser",
    addSeconds: "/tracker/addSeconds",
}