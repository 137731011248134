import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { login } from '../services/api';
import { checkUserByToken } from '../services/api';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import logo from "../assets/images/logo.png"
import { FaUser, FaLock, FaEye, FaEyeSlash } from "react-icons/fa6";

const Login = () => {
    useEffect(() => {
        beforeLogin();
    }, [])

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordShow, setpasswordShow] = useState(false)
    let navigate = useNavigate();

    const Session = JSON.parse(sessionStorage.getItem('User'));
    if (Session) return navigate("/project", { replace: true });

    async function loginUser() {
        if (!email) {
            return toast("Employee id is required!", { autoClose: 3000, toastId: 'Employee id' });
        }
        if (!password && !password.trim()) {
            return toast("Password is required!", { autoClose: 3000, toastId: 'password' });
        }

        let response = await login({ email, password });
        if (response.code == 200) {
            console.log(response)
            window.api.receive(response.token)
            sessionStorage.setItem('User', JSON.stringify(response.user));
            navigate("/project", { replace: true });
        } else {
            return toast(response.message, { autoClose: 3000, toastId: 'message' });
        }
    }
    async function beforeLogin() {
        await window.api.sendData().then(async res => {
            let returned = JSON.parse(res)
            console.log(returned)
            if (!returned) {
                return toast("login with your credentials", { autoClose: 3000, toastId: 'credentials' })
            }
            await checkUserByToken(returned).then(result => {
                if (result.code == 200) {
                    sessionStorage.setItem('User', JSON.stringify(result.result));
                    navigate("/project", { replace: true });
                } else {
                    return
                }
            }).catch(err => {
                return toast(err, { autoClose: 3000, toastId: 'error' })
            })
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <>
            <div className="screen-container">
                <div className="logo mt-5">
                    <img src={logo} alt="" className='img-fluid' />
                </div>
                <div className="login-content">
                    <div className="login-section d-flex flex-column justify-content-center align-item-center">
                        <h2>Login to Tracker</h2>
                        <div className="input-box mb-3 position-relative">
                            <FaUser color='var(--primary)' />
                            <input type="text" placeholder="Employee Id" onChange={(e) => setEmail(e.target.value)} className="form-control w-100"></input>
                        </div>
                        <div className="input-box mb-2 position-relative">
                            <FaLock color='var(--primary)' />
                            <input type={passwordShow ? "text" : "password"} placeholder="Password" onChange={(e) => setPassword(e.target.value)} className="form-control w-100"></input>
                            <div className="password-show">
                                {passwordShow ? <FaEyeSlash color='#5F5F5F' onClick={() => setpasswordShow(!passwordShow)} /> : <FaEye color='#5F5F5F' onClick={() => setpasswordShow(!passwordShow)} />}
                            </div>
                        </div>

                        <div className="login-btn mt-3">
                            <button className="w-100 rounded-pill text-white py-2 border-0" onClick={() => loginUser()}>Login</button>
                        </div>

                    </div>
                    <div className="login-policy">
                        <p className='text-center'>Tracker uses cookies for analytics and personalized content. By using Tracker's services, you agree to
                            this use of cookies.</p>
                    </div>
                </div>
            </div>
        </>
    )

}


export default Login;