import React, { useEffect, useRef, useState } from 'react';
import { FaRegEdit, FaRegSave } from "react-icons/fa";
import { IoArrowBackOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { toast } from 'react-toastify';
import { getTrackerInfo, startTracker, trackingHours, weeklyHours } from '../services/api';
const moment = require('moment-timezone');
moment.tz.setDefault("utc");

const Tracker = () => {
    const [count, setCount] = useState(0);
    const [image, setImage] = useState('');
    const location = useLocation();
    const [trackerDetails, setTrackerDetails] = useState({});
    let [trackerHours, setTrackerHours] = useState(0);
    const [notes, setNotes] = useState("");
    const [memoEdit, setMemoEdit] = useState(false)
    const [time, setTime] = useState(0);
    const [checked, setChecked] = useState(false);
    var countTime = useRef(0);

    let navigate = useNavigate();
    var capturedTime = null;
    var trackerTimer = null;
    var lastCaptureInterval;
    var sessionTime = 0;
    var currentSession = 0;
    var sessionInterval;

    let dataFromProjects = location.state;

    const projectId = window.location.pathname.split(':');
    if (!projectId[1]) return navigate('project', { replace: true });

    useEffect(() => { trackerInfo(); }, []);

    useEffect(() => {
        getTrackingHours();
        getWeeklyHours();

        if (checked) {
            sessionTime = 0; setTime(0);
            startTracker(projectId[1]);
            window.api.screenshotCapture({ nextChecked: checked, projectId: projectId[1], capture: false });
            sessionStorage.setItem('keyPressed', JSON.stringify(moment()));

            window.api.getCaptureTime().then((res) => {

                let response = {currentSession: 0 };
                if(res) response = JSON.parse(res);
                
                currentSession += response.currentSession ? response.currentSession : 0;

                sessionInterval = setInterval(() => {
                    sessionTime += 60;
                    setTime(sessionTime)
                    currentSession += 60;
                    console.log(currentSession)
                    window.api.updateCaptureTime({ currentSession });
                }, 60000);

            function timeout() {
                let timer = getScreenshotTime(currentSession);

                console.log(timer, currentSession, 'timer which is  selectd for ss================');
                var seconds = timer * 60;
                trackerTimer = setTimeout(() => {
                    console.log(seconds, countTime.current, 'currentSessioncurrentSessioncurrentSessioncurrentSession');
                    seconds += countTime.current;
                    timer = (seconds < 720) ? Math.round(seconds / 60) : 12;// convert seconds  into minutes
                    window.api.screenshotCapture({ nextChecked: checked, projectId: projectId[1], capture: true, time: timer, seconds: seconds, notes: notes });
                    currentSession = 0;
                    countTime.current = 0;
                    window.api.updateCaptureTime({ currentSession: 0 });
                    capturedTime = moment(new Date(), 'YYYY/MM/DD HH:mm');
                    clearInterval(lastCaptureInterval);
                    interval();
                    setTrackerHours(trackerHours += seconds);
                    dataFromProjects.currentWeekSeconds = dataFromProjects.currentWeekSeconds += seconds;
                    timeout();
                }, seconds * 1000);

            } timeout();


            function interval() {
                lastCaptureInterval = setInterval(() => {
                    var lastScreen = moment(capturedTime, 'YYYY/MM/DD HH:mm').startOf("seconds").fromNow();
                    var image = JSON.parse(sessionStorage.getItem('image'));
                    setCount(lastScreen);
                    setImage(image);
                    checkKeyPressOrNot();
                }, 2000);
            }

        });
        } else {
            window.api.getCaptureTime().then((res) => {

                let response = {currentSession: 0 };
                if(res) response = JSON.parse(res);

                countTime.current = response.currentSession;
                window.api.screenshotCapture({ nextChecked: checked, projectId: projectId[1], capture: false });
                clearInterval(lastCaptureInterval);
                console.log(checked, 'checked false');
                clearTimeout(trackerTimer);
                clearInterval(sessionInterval);
            });
        }
        return () => {
            clearInterval(lastCaptureInterval),
                clearTimeout(trackerTimer),
                clearInterval(sessionInterval);
        }
    }, [checked]);


    function getScreenshotTime(prevSession) {
        let Session = Math.round(prevSession / 60); 
        let max = 12 - Session;

        if (Session > 12) {
            console.log('inside zero cond');
            return 0;
        } else if (max > 5) {
            return Math.floor(Math.random() * (max - 5 + 1)) + 5;
        } else {
            return Math.floor(Math.random() * (max - 1 + 1)) + 1;
        }
    }


    const back = () => {
        if (checked) return toast("Please turn off tracker!");
        clearInterval(lastCaptureInterval);
        navigate(`/project`);
    }

    const handleChange = async nextChecked => {
        setTimeout(() => {
            setChecked(nextChecked);
        }, 300);
    };


    async function trackerInfo() {
        const trackerInfo = await getTrackerInfo({ projectId: projectId[1] });
        if (trackerInfo.code == 200) {
            setTrackerDetails(trackerInfo.result);
        } else if (trackerInfo.code == 401) {
            navigate('/');
        } else {
            console.log(trackerInfo, 'else');
        }
    }


    async function getTrackingHours() {
        const trackerHours = await trackingHours({ projectId: projectId[1], trackingDate: moment().format('YYYY-MM-DD') });
        if (trackerHours.code == 200) {
            setTrackerHours(trackerHours.result.seconds);
        } else if (trackerHours.code == 401) {
            navigate('/');
        }
    }

    useEffect(() => {
        console.log(trackerHours, "trackerHours trackerHours trackerHours trackerHours")

    }, [trackerHours])

    async function checkKeyPressOrNot() {
        let checkKey = await JSON.parse(sessionStorage.getItem('keyPressed'));
        let now = moment();
        let addedTime = moment(checkKey).add(5, 'minutes');
        if (moment(now).isSameOrAfter(addedTime)) {
            console.log("comes in if condition");
            window.api.notification({
                title: 'Tracker app',
                subtitle: 'Tracker app',
                message: 'Your tracker is switched off because you have not press any key from long time',
                theme: 'light',
                native: true // when using native, your OS will handle theming.
            });
            sessionStorage.setItem("seconds", 0);
            setChecked(false);
        }
    }

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const hoursStr = String(hours).padStart(2, '0');
        const minutesStr = String(minutes).padStart(2, '0');
        return `${hoursStr}hrs ${minutesStr}m`;
    };

    const formatColanTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const hoursStr = String(hours).padStart(2, '0');
        const minutesStr = String(minutes).padStart(2, '0');
        return `${hoursStr}:${minutesStr}`;
    };

    const getWeeklyHours = async () => {
        const result = await weeklyHours(projectId[1]);
        if (result.code == 200) {
            dataFromProjects.currentWeekSeconds = result.result
        }
    };

    return (
        <div className='tracker-container'>
            <div className="topbar-container">
                <div className="d-flex justify-content-between align-items-center">
                    <div className='d-flex align-items-center'>
                        <div className="project-tracker-title d-flex align-items-center">
                            <div className="back-btn pointer me-2" onClick={() => back()}><IoArrowBackOutline color='var(--white)' /></div>
                            <h3 className='mb-0'>{trackerDetails?.name}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="white-card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div>
                        <h4 className='tracker-time mb-0'>{formatTime(trackerHours)}</h4>
                        <p className='mb-0'>Today working hours</p>
                    </div>
                    <div style={{ right: "6px", position: "relative" }}><label className="switch" htmlFor="material-switch">
                        <Switch
                            onChange={handleChange}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offColor="#E5E5E5"
                            onColor='#00ACCE'
                            activeBoxShadow="0 0 0 0"
                            handleDiameter={28}
                            height={36}
                            width={64}
                            checked={checked}
                            className="react-switch"
                            id="material-switch" />
                    </label>
                    </div>
                </div>
                <div className="white-card-container pb-0 d-flex flex-column justify-content-between pt-3">
                    <div className='project-timer'>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <p className='mb-0'>Current Session</p>
                            <h6 className='mb-0'>
                                {formatTime(time)}
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <p className='mb-0'>This Week</p>
                            <h6 className='mb-0'>{`${formatColanTime(dataFromProjects.currentWeekSeconds)}hrs /${dataFromProjects.hoursLimit}hrs`}</h6>
                        </div>
                        <div className="memo mb-3">
                            <label htmlFor="memo" className='mb-2'>Notes</label>
                            <div className="memo-box position-relative">
                                <textarea name="memo" placeholder='Notes here...' id="memo" value={notes} onChange={(e) => setNotes(e.target.value)} className='form-control' disabled={memoEdit ? false : true}></textarea>
                                {
                                    memoEdit ? <div className="save-btn" onClick={() => setMemoEdit(false)}>
                                        <button><FaRegSave /></button>
                                    </div> : <div className="edit-btn" onClick={() => setMemoEdit(true)}>
                                        <button><FaRegEdit /></button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="screenshot-area">
                        <div className="screen-capture">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className='mb-2'>Latest Screen Capture</p>
                                <p className='mb-2' >{count ? count : 'No Screen Capture'}</p>
                            </div>
                            <div className="screen-capture-img">
                                <img src={image ? image : require('../image/defaultCaptureImg.jpg')} width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Tracker;