const axios = require('axios').default;
import { Urls } from '../utils/endPoint';
const moment = require('moment-timezone');
moment.tz.setDefault('utc');


export const login = async (data) => {
    return new Promise(async (resolve, reject) => {
        await axios.post(Urls.baseUrl + Urls.login, data).then(async response => {
            const data = response.data;
            resolve(data);
            // console.log(response);
            // if (data.code != 401) {
            // } else {
            // }
        }).catch(function (error) {
            reject(error);
        });
    });
}




export const getProjects = async (data) => {
    const Session = JSON.parse(sessionStorage.getItem('User'));

    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + Urls.projectListing,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    "Authorization": `Bearer ${Session.token}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const checkUserByToken = async (data) => {
    return new Promise(async (resolve, reject) => {
        axios.get(Urls.baseUrl + Urls.checkUser,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    "Authorization": `Bearer ${data}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}



export const getTrackerInfo = async (data) => {
    const Session = JSON.parse(sessionStorage.getItem('User'));

    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + Urls.trackingInfo,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    "Authorization": `Bearer ${Session.token}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}


export const trackingHours = async (data) => {
    const Session = JSON.parse(sessionStorage.getItem('User'));

    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + Urls.trackingHours,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    "Authorization": `Bearer ${Session.token}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const addSeconds = async (data) => {
    const Session = JSON.parse(sessionStorage.getItem('User'));

    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + Urls.addSeconds,
            data,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    "Authorization": `Bearer ${Session.token}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const startTracker = async (projectId) => {
    const Session = JSON.parse(sessionStorage.getItem('User'));
    const trackingStatus = JSON.parse(sessionStorage.getItem('trackingStatus'));
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + '/tracker/startSession',
            {
                trackingDate: moment().format('YYYY-MM-DD'),
                projectId: projectId
            },
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    "Authorization": `Bearer ${Session.token}`
                }
            }
        ).then((response) => {
            resolve(response.data);
            sessionStorage.setItem('lastScreenCaptured', JSON.stringify(moment()));
        }).catch((error) => {
            reject(error);
        });
    });
}

export const weeklyHours = async (projectId) => {
    const Session = JSON.parse(sessionStorage.getItem('User'));
    return new Promise(async (resolve, reject) => {
        axios.post(Urls.baseUrl + '/tracker/weeklyHours',
            {
                projectId: projectId
            },
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    "Authorization": `Bearer ${Session.token}`
                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}
